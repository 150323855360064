<template>
  <div>
    <v-container>
      <transition-group name="list-animated">
        <v-row
          v-for="(line, index) in formData.creditors"
          :key="index"
          class="rounder mt-4 mb-7 pt-4 list-animated-item"
        >
          <v-col>
            <v-row no-gutters class="row">
              <v-col cols="12">
                <div style="position: relative">
                  <v-btn
                    fab
                    dark
                    x-small
                    absolute
                    top
                    elevation="0"
                    color="#57b3dd"
                    @click="removeCreditor(index)"
                    v-if="formData.creditors.length > 1"
                    style="right: 0px; top: -45px"
                  >
                    <v-icon dark> mdi-close </v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12">
                <p
                  class="text-h6 text-justify font-weight-bold"
                  style="display: none"
                >
                  Creditor {{ index + 1 }} - {{ line.creditors_name }}
                </p>
                <v-text-field
                  label="Creditor Name"
                  v-model="line.creditors_name"
                  large
                  block
                  outlined
                  hide-details="auto"
                  class="mb-3"
                  :rules="[rules.required]"
                ></v-text-field>
                <div
                  style="
                    text-align: right;
                    width: 100%;
                    font: normal normal normal 12px/16px Nunito;
                    letter-spacing: 0.36px;
                    color: #606368;
                    position: relative;
                  "
                >
                  <span style="position: relative; right: 0px; top: -5px"
                    >Example. Name of Bank / Name of Credit Provider</span
                  >
                </div>
              </v-col>
              <v-col cols="12">
                <J3Currency
                  label="Outstanding Balance"
                  v-model="line.balance"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" class="pl-0 pr-2">
                <J3YesNo v-model="line.include_in_assessment" v-show="false"
                  ><template v-slot:heading
                    >Include this debt in your assessment?
                  </template>
                </J3YesNo>

                <J3YesNo v-model="line.arrears"
                  ><template v-slot:heading
                    >Are you in arrears with this debt?
                  </template>
                </J3YesNo>
                <J3YesNo v-model="line.against_property"
                  ><template v-slot:heading
                    >Is this debt secured against a property?
                  </template>
                </J3YesNo>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </transition-group>
    </v-container>
    <v-container>
      <v-row>
        <v-col>
          <div class="dotted-line"></div>

          <div class="text-center" v-show="false">
            <v-chip class="my-2" color="#EBECF0">
              This&nbsp;<b>won't affect your credit score!</b>
            </v-chip>
          </div>

          <v-btn
            class="mt-2 cta"
            large
            block
            outlined
            color="#6dbce1"
            @click="addLine"
          >
            &plus; Add another creditor
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import J3YesNo from "./J3YesNo.vue";
import J3Currency from "./J3Currency.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "Creditors",
  components: { J3YesNo, J3Currency },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
      },
      lines: [
        {
          countryCode: null,
          number: null,
          phoneUsageType: null,
        },
      ],
      blockRemoval: true,
      phoneUsageTypes: [
        {
          label: "Home",
          value: "home",
        },
        {
          label: "Work",
          value: "work",
        },
        {
          label: "Mobile",
          value: "mobile",
        },
        {
          label: "Fax",
          value: "fax",
        },
      ],
      countryPhoneCodes: [
        {
          label: "+90",
          value: "+90",
        },
        {
          label: "+1",
          value: "+1",
        },
      ],
    };
  },
  computed: {
    ...mapState(["firebase_token", "formData"]),
  },
  watch: {
    lines() {
      this.blockRemoval = this.lines.length <= 1;
    },
  },
  methods: {
    ...mapActions(["removeCreditor"]),
    addLine() {
      let checkEmptyLines = this.lines.filter((line) => line.number === null);
      // if (checkEmptyLines.length >= 1 && this.lines.length > 0) {
      //   return;
      // }
      this.formData.creditors.push({
        creditors_name: null,
        balance: null,
        include_in_assessment: "yes",
        arrears: "no",
        against_property: "no",
      });
    },
    removeLine(lineId) {
      if (!this.blockRemoval) {
        this.formData.creditors.splice(lineId, 1);
      }
    },
  },
  mounted() {
    //this.addLine();
    // this.formData.lines.push({
    //   countryCode: null,
    //   number: null,
    //   phoneUsageType: null,
    // });
  },
};
</script>
<style>
.rounder {
  border: 2px solid #dadbdf;
  border-radius: 0.85rem;
}
.dotted-line {
  border-top: 1px dashed #dadbdf; /* Adjust color as needed */
  width: 100%;
  margin: 25px 0;
}
.list-animated-item {
  transition: opacity 0.5s;
}
.list-animated-enter {
  opacity: 0;
}
.list-animated-move {
  opacity: 0;
}
.list-animated-leave-to {
  opacity: 0;
}
</style>
