<template>
	<v-text-field
		prefix="£"
		:label="label"
		v-model="currencyLocal"
		large
		block
		outlined
		hide-details="auto"
		class="mb-3"
		v-maska:[options]
		:rules="rules"
	></v-text-field>
</template>

<script>
export default {
	name: "J3Currency",
	data() {
		return {
			options: {
				//mask: "9,99#.##",
				mask: "9,99#",
				tokens: {
					9: { pattern: /[0-9]/, repeated: true },
				},
				reversed: true,
			},
		};
	},
	props: {
		label: {
			type: String,
			default: "",
		},
		value: {
			type: String,
		},
		rules: {
			type: Array,
		},
	},
	computed: {
		currencyLocal: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				this.$emit("input", value);
			},
		},
	},
	watch: {
		value: {
			handler(val, oldVal) {
				this.$emit("input", val);
			},
			deep: true,
		},
	},
};
</script>
